import React from "react";
import { gsap } from "gsap";

export const Circle = React.forwardRef(({ size, delay }, ref) => {
  const element = React.useRef();

  React.useImperativeHandle(
    ref,
    () => ({
      moveTo(x, y) {
        gsap.to(element.current, { x, y, delay });
      },
      hide() {
        gsap.to(element.current, { opacity: 0 });
      },
    }),
    [delay]
  );

  return <div className={`circle ${size}`} ref={element} />;
});
