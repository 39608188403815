import React from "react";

import Layout from "../components/Layout/Layout";
import Intro from "../components/Intro";

export default function IndexPage() {
  return (
    <Layout>
      <Intro />
    </Layout>
  );
}
