import React from "react";
import { navigate } from "gatsby";
import { gsap } from "gsap";

import useArrayRef from "../../hooks/useArrayRef";
import Circle from "../Circle";

const startText = `Click on me, and let's go...`;

const screens = {
  start: "start",
  welcome: "welcome",
  choose: "choose",
  resume: "resume",
  story: "story",
};

export default function Intro({ children }) {
  const [screen, setScreen] = React.useState(screens.start);
  const [circleRefs, addCircleRef] = useArrayRef();
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    circleRefs.current.forEach(ref => ref.moveTo(0, 0));

    const onMove = ({ clientX, clientY }) =>
      circleRefs.current.forEach(ref => ref.moveTo(clientX, clientY));

    window.addEventListener("pointermove", onMove);

    return () => {
      window.removeEventListener("pointermove", onMove);
      circleRefs.current.forEach(ref => gsap.killTweensOf(ref));
      gsap.killTweensOf(buttonRef);
    };
  }, []);

  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      backgroundColor: "#e77614",
      scale: 1.1,
    });
  };

  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, {
      backgroundColor: "#28a92b",
      scale: 1,
    });
  };

  const onStartClick = () => {
    circleRefs.current.forEach(ref => ref.hide());
    gsap.to(buttonRef.current, {
      opacity: 0,
      onComplete: () => setScreen(screens.welcome),
    });
  };

  if (screen === screens.resume) {
    navigate("/resume");
  }

  const isShowCircles =
    screen === screens.start ||
    screen === screens.story ||
    screen === screens.choose;

  return (
    <div className="intro-container flex xy-center">
      {screen === screens.welcome && (
        <TextChanger
          textArray={[
            "Welcome to the my personal site!",
            "Here you will find a lot of interesting things",
            "For example my resume",
            "or some interesting facts about me",
            "Sooo, let's go!",
            "",
          ]}
          delay={3_000}
          onFinish={() => setScreen(screens.choose)}
        />
      )}

      {screen === screens.start && (
        <div
          className="button text center flex xy-center ma20 pa20"
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          onClick={onStartClick}
          ref={buttonRef}
        >
          {startText}
        </div>
      )}

      {screen === screens.choose && <ChooseSection onChoose={setScreen} />}

      {isShowCircles && (
        <>
          <Circle size="sm" ref={addCircleRef} delay={0} />
          <Circle size="md" ref={addCircleRef} delay={0.1} />
          <Circle size="lg" ref={addCircleRef} delay={0.2} />
        </>
      )}
    </div>
  );
}

function ChooseSection({ onChoose }) {
  const [buttonRefs, addButtonRef] = useArrayRef();

  React.useEffect(
    () => () => buttonRefs.current.forEach(ref => gsap.killTweensOf(ref)),
    []
  );

  const onEnter = ({ currentTarget }) => {
    const otherButtons = buttonRefs.current.filter(x => x !== currentTarget);
    gsap.to(currentTarget, { backgroundColor: "#e77614", scale: 1.1 });

    otherButtons.forEach(ref => gsap.to(ref, { scale: 0.9 }));
  };

  const onLeave = () => {
    buttonRefs.current.forEach(ref =>
      gsap.to(ref, { backgroundColor: "#28a92b", scale: 1 })
    );
  };

  const onClick =
    selection =>
    ({ currentTarget }) => {
      const otherButtons = buttonRefs.current.filter(x => x !== currentTarget);

      otherButtons.forEach(ref => gsap.to(ref, { y: 1000 }));
      gsap.to(currentTarget, {
        y: -1000,
        onComplete: () => onChoose(selection),
      });
    };

  return (
    <>
      <div
        className="choose-button shown button text lg flex xy-center pa10"
        ref={addButtonRef}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onClick={onClick("story")}
      >
        Story
      </div>
      <div
        className="choose-button shown button text lg flex xy-center pa10"
        ref={addButtonRef}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onClick={onClick("resume")}
      >
        Resume
      </div>
    </>
  );
}

function TextChanger({ textArray, delay, onFinish }) {
  const [index, setIndex] = React.useState(0);
  const textRef = React.useRef(null);
  const timer = React.useRef(null);

  React.useEffect(() => {
    timer.current = setInterval(() => setIndex(p => p + 1), delay);
  }, []);

  React.useEffect(() => {
    if (index + 1 === textArray.length) {
      clearInterval(timer.current);
      onFinish?.();
    }
  }, [index]);

  return (
    <div className="text center xxl blink3s px20" ref={textRef}>
      {textArray[index]}
    </div>
  );
}
